
ul.entities {
	list-style: none;
	padding: 0;
	line-height: 25px;

	li {
		margin-bottom: 5px;
		padding: 0 10px;

		&.players {
			margin-bottom: 25px;
		}
		a {
			font-size: 14px;
		}
		.q-field {
			width: 90px;
			padding: 0;

			.row {
				padding: 0;
			}
		}
	}
}
.q-tab-panel {
	padding: 15px 0 0 0 !important;
}
